import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Table } from '@/components/TableLayout/Table';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { useSimulationSchedules } from '@/service/hooks/api/useSimulationSchedules';
import { ContextMenuItem } from '@/components/TableLayout/types';
import { SimulationSchedule } from '@/service/hooks/types/SimulationSchedule';
import { ConfirmationDialog } from '@/components/ConfirmationDialog/ConfirmationDialog';
import {
  COMMON_STRING,
  CONFIRMATION_STRING,
  SUCCESS_STRING,
} from '@/constants/dictionary';
import { TABLE_CELLS } from './constants';

export const SimulationSchedules = () => {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [schedule, setSchedule] = useState<SimulationSchedule | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { simulationSchedules, isLoading, deleteSimulationSchedule, mutate } =
    useSimulationSchedules(schedule?.id);

  const deleteSimulator = async () => {
    await deleteSimulationSchedule();
    setIsScheduleOpen(false);
    setSchedule(null);
    enqueueSnackbar(SUCCESS_STRING.SIMULATION_SCHEDULE_DELETED(schedule?.id), {
      variant: 'success',
    });
    mutate(simulationSchedules);
  };

  const menuItem: ContextMenuItem[] = [
    {
      Icon: DeleteIcon,
      label: COMMON_STRING.DELETE,
      onClick: () => setIsScheduleOpen(true),
      ref: setAnchorEl,
    },
  ];

  return (
    <>
      <Table
        data={simulationSchedules}
        tableHeadCells={TABLE_CELLS}
        isLoading={isLoading}
        tableName={TAB_NAMES.SIMULATION_SCHEDULES}
        setRowData={setSchedule}
        rowData={schedule}
        menuItems={menuItem}
      />
      <ConfirmationDialog
        isOpen={isScheduleOpen}
        cancelHandler={() => {
          setIsScheduleOpen(false);
          setSchedule(null);
        }}
        anchorEl={anchorEl}
        confirmHandler={deleteSimulator}
        title={CONFIRMATION_STRING.TITLE.SIMULATION_SCHEDULE(schedule?.id)}
        body={CONFIRMATION_STRING.BODY.SIMULATION_SCHEDULE}
        confirmLabel={COMMON_STRING.DELETE}
      />
    </>
  );
};
