import { UsedFilterType } from '@/components/Filters/types';

export type ParsedFilterType = { [key: string]: string | number } | undefined;

export const parseSelectedFilters = (
  filters?: UsedFilterType[],
): ParsedFilterType => {
  if (!filters || !filters.length) return;

  return filters.reduce((acc, current) => {
    if (current.options.length === 0) return acc;

    const filterValues = current.options.map((option) => option.name);

    return { ...acc, [current.paramName]: filterValues };
  }, {});
};
