import { blue, grey, orange } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import { AnalyticsThemeOptions } from '@/context/theme/types';
import { ENVIRONMENTS } from '@/constants/environment';
import { FIXTURE_STATUS } from '@/constants/dictionary';

export const LIGHT_PALETTE_MODE: PaletteMode = 'light';
export const DARK_PALETTE_MODE: PaletteMode = 'dark';

const envContainerColors = {
  env: {
    [ENVIRONMENTS.DEV]: '#FDD835',
    [ENVIRONMENTS.LOCAL]: '#E87638FF',
    [ENVIRONMENTS.STAGING]: '#43A047',
    [ENVIRONMENTS.SANDBOX]: '#2196F3',
    [ENVIRONMENTS.PROD]: '#E039CAFF',
  },
};

const fixtureStatusColors = {
  status: {
    [FIXTURE_STATUS.LIVE]: '#FF5252',
    [FIXTURE_STATUS.SCHEDULED]: '#9E9E9E',
    [FIXTURE_STATUS.POSTPONED]: '#FB8400',
    [FIXTURE_STATUS.COMPLETED]: '#44A047',
    [FIXTURE_STATUS.CANCELLED]: '#D3302F',
    [FIXTURE_STATUS.SUSPENDED]: '#FD5B04',
    [FIXTURE_STATUS.ABANDONED]: '#9575CD',
    [FIXTURE_STATUS.UNVERIFIED]: '#FFC109',
  },
};

export const customOverridesLight: AnalyticsThemeOptions = {
  ...envContainerColors,
  ...fixtureStatusColors,
  palette: {
    primary: {
      main: orange[900],
    },
    background: {
      default: grey[100],
    },
  },
  typography: {
    caption: {
      color: grey[700],
    },
  },
  json: {
    main: grey[700],
    blue: '#2196F3',
    red: '#D32F2F',
    purple: '#6A1B9A',
    background: grey[100],
  },

  tableBorderColor: grey[300],
  tableHeaderColor: grey[100],
  link: {
    default: blue[700],
  },
};

export const customOverridesDark: AnalyticsThemeOptions = {
  ...envContainerColors,
  ...fixtureStatusColors,
  palette: {
    primary: {
      main: orange[900],
      contrastText: '#fff',
    },
    background: {
      default: '#202020',
      paper: '#383838',
    },
    text: {
      primary: grey[200],
    },
    action: {
      active: grey[200],
    },
  },
  typography: {
    caption: {
      color: grey[400],
    },
  },
  json: {
    main: grey[400],
    blue: '#42A5F5',
    red: '#E53935',
    purple: '#BA68C8',
    background: grey[900],
  },
  tableBorderColor: grey[800],
  tableHeaderColor: grey[700],
  link: {
    default: blue[400],
  },
};

export const overrides: AnalyticsThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: grey[900],
          color: grey[100],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: orange[900],
          color: grey[100],
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          '&.Mui-selected': {
            border: '1px solid',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: grey[500],
        },
      },
    },
  },
  typography: {
    fontFamily: '"SF-UI", Arial',
    caption: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
      letterSpacing: '0.0833em',
    },
    h1: {
      fontSize: '2em',
    },
    subtitle1: {
      fontSize: '0.75em',
    },
    subtitle2: {
      fontSize: '0.6em',
      lineHeight: 1.4,
    },
  },
  transitions: {
    duration: {
      enteringScreen: 175,
      leavingScreen: 145,
    },
  },
  palette: {
    secondary: {
      light: grey[700],
      main: grey[800],
      dark: grey[900],
      contrastText: grey[50],
    },
  },
};
