import useSWR from 'swr';
import { useToken } from '@/service/hooks/api/useToken';
import { API } from '@/service/apiRequests/constants';
import { fetchData, Fetches } from '@/service/apiRequests/fetchService';
import { URLS } from '@/utils/env';
import { PaginatedResult } from '@/components/TableLayout/types';
import { FilterOptionType } from '@/components/Filters/types';

export const useSports = () => {
  const token = useToken();

  const { data: sports, error } = useSWR<PaginatedResult<FilterOptionType>>(
    {
      token,
      endpoint: API.SPORTS.GET,
    },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FP_BASE_URL),
  );

  return { sports, error, isLoading: !sports && !error };
};
