import { COMMON_STRING } from '@/constants/dictionary';
import { ScorerInfo } from '@/service/hooks/types/Scorers';

export const getScorerFullName = (user: ScorerInfo[]) => {
  if (!user.length) return COMMON_STRING.N_A;

  const firstName = user[0]?.firstname || '';
  const lastName = user[0].lastname || '';

  return `${firstName} ${lastName}`;
};
