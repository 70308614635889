import { FC } from 'react';
import { useScorerUser } from '@/service/hooks/api/useScorerUser';
import { ConnectedScorer } from '@/service/hooks/types/Scorers';
import { getScorerFullName } from './helpers/getScorerName';

export type ScorerCellProps = {
  scorer: ConnectedScorer;
};

export const ScorerNameCell: FC<ScorerCellProps> = ({ scorer }) => {
  const scorerId = scorer.scorerId ?? '';
  const { user } = useScorerUser(scorerId);

  if (!user) {
    return;
  }

  return getScorerFullName(user);
};
