import { FC } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import { FilterOptionType, UsedFilterType } from '@/components/Filters/types';

type UsedFilterListProps = {
  usedFiltersList?: UsedFilterType[];
  onFilterRemove: (paramIndex: number, filterIndex: number) => void;
};

const createFilterChip = (
  columnName: string,
  options: FilterOptionType[],
  paramIndex: number,
  onDelete: (paramIndex: number, filterIndex: number) => void,
) => {
  return (
    <Box key={columnName}>
      {options.map(({ id, name }, filterIndex) => {
        return (
          <Chip
            key={`${columnName}-${id}`}
            sx={{ mr: 0.5 }}
            size='small'
            label={`${columnName}:${name}`}
            onDelete={() => onDelete(paramIndex, filterIndex)}
          />
        );
      })}
    </Box>
  );
};

const getFiltersChips = (
  filters: UsedFilterType[],
  onDelete: (filterIndex: number, paramIndex: number) => void,
) =>
  filters.map(({ label, options }, index) => {
    return createFilterChip(label, options, index, onDelete);
  });

export const UsedFilterList: FC<UsedFilterListProps> = ({
  usedFiltersList,
  onFilterRemove,
}) => {
  return (
    <Stack direction='row' gap={1}>
      <Stack
        p={0.5}
        direction='row'
        gap={0.5}
        alignItems='center'
        flexWrap='wrap'
      >
        {usedFiltersList &&
          !!usedFiltersList.length &&
          getFiltersChips(usedFiltersList, onFilterRemove)}
      </Stack>
    </Stack>
  );
};
