import { FC } from 'react';
import { ChipSelectFilter } from '@/components/ChipSelectFilter/ChipSelectFilter';
import { useSports } from '@/service/hooks/api/useSports';
import { FilterBaseProps, FilterOptionType } from '@/components/Filters/types';
import { FILTER_TYPE, FILTER_PARAMS } from '../Filters/constants';

type SportsFilterProps = FilterBaseProps;

export const SportsFilter: FC<SportsFilterProps> = ({
  onSelect,
  selectedValue,
}) => {
  const { sports, isLoading } = useSports();
  const handleChange = (options: FilterOptionType[]) => {
    onSelect(options, {
      label: FILTER_TYPE.SPORT,
      paramName: FILTER_PARAMS.SPORTS,
    });
  };

  return (
    <ChipSelectFilter
      onChange={handleChange}
      selectedValue={selectedValue}
      isLoading={isLoading}
      options={sports?.data}
    />
  );
};
