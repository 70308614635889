export const ROUTE_PATHS = {
  HOME: '/',
  OKTA_CALLBACK: '/auth',
  CONNECTED_SCORERS: '/connected-scorers',
  LIVE_DATA_CLIENTS: '/live-data-clients',
  FIXTURE_ACTION_LATENCY: '/fixture-action-latency',
  SIMULATIONS: '/simulations',
  FIXTURE_IMPORT: '/fixture-import',
  SIMULATION_SCHEDULES: '/simulation-schedules',
  AVERAGE_LATENCIES: '/average-latencies',
};
