import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { ROUTE_PATHS } from '@/router/constants';

const oktaConfig = new OktaAuth({
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}${ROUTE_PATHS.OKTA_CALLBACK}`,
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    return () => (oktaConfig.options.restoreOriginalUri = undefined);
  }, []);

  return (
    <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
