import useSWR from 'swr';
import { URLS } from '@/utils/env';
import { Fetches, fetchData } from '@/service/apiRequests/fetchService';
import { API } from '@/service/apiRequests/constants';
import { ConnectedScorer } from '@/service/hooks/types/Scorers';
import { useToken } from './useToken';

export const useConnectedScorers = () => {
  const token = useToken();
  const { data, error } = useSWR<ConnectedScorer[]>(
    { token, endpoint: API.SCORER.GET },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FA_BASE_URL),
  );

  return { data, error, isLoading: !data && !error };
};
