import { ScorerNameCell } from '@/components/Table/Scorers/ScorerNameCell';
import { COMMON_STRING, STATUS } from '@/constants/dictionary';
import { FixtureCell } from '@/components/TableLayout/FixtureCell';
import { HeadCell } from '@/components/TableLayout/types';
import { formatDate } from '@/utils/formatDate';
import { ConnectedScorer } from '@/service/hooks/types/Scorers';
import { URLS } from '@/utils/env';

export const TABLE_HEAD_CELLS: HeadCell[] = [
  {
    label: 'Scorer name',
    value: (scorer: ConnectedScorer) => <ScorerNameCell scorer={scorer} />,
  },
  {
    label: 'Email',
    value: (scorer: ConnectedScorer) => scorer.email || COMMON_STRING.N_A,
  },
  {
    label: 'Fixture id',
    value: ({ fixtureId }: ConnectedScorer) => (
      <FixtureCell fixtureId={fixtureId} url={URLS.MT_BASE_URL} />
    ),
  },
  {
    label: 'Connected info (local time)',
    value: (scorer: ConnectedScorer) => formatDate(scorer.connectedTimestamp),
  },
  {
    label: 'Last communicated (local time)',
    value: (scorer: ConnectedScorer) => formatDate(scorer.lastCommunication),
  },
  {
    label: 'Battery',
    value: (scorer: ConnectedScorer) =>
      scorer.batteryLevel !== STATUS[COMMON_STRING.BATTERY_CHARGING]
        ? scorer.batteryLevel
        : COMMON_STRING.BATTERY_CHARGING,
  },
  {
    label: 'Device id',
    value: (scorer: ConnectedScorer) => scorer.deviceId || COMMON_STRING.N_A,
  },
  {
    label: 'App Version',
    value: (scorer: ConnectedScorer) =>
      scorer.scoringAppVersion || COMMON_STRING.N_A,
  },
];
