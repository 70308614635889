import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DISPLAY_ENV, Environment } from '@/constants/environment';
import { AnalyticsTheme } from '@/context/theme/types';
import { getEnvColor } from '@/context/theme/theme';

export const EnvironmentDetails = () => {
  const environment = import.meta.env.MODE as Environment;
  const theme = useTheme<AnalyticsTheme>();
  const envColor = getEnvColor(theme) || 'none';

  if (!DISPLAY_ENV.includes(environment)) {
    return;
  }

  return (
    <Typography
      component='p'
      bgcolor={envColor}
      borderRadius={theme.spacing(0.5)}
      color={theme.palette.getContrastText(envColor)}
      p={theme.spacing(0.25, 0.5)}
      variant='subtitle2'
      lineHeight={1.67}
    >
      {environment}
    </Typography>
  );
};
