import { HeadCell } from '@/components/TableLayout/types';
import { ActionCell } from '@/components/TableLayout/ActionCell';
import { LiveDataClient } from '@/service/hooks/types/LiveDataClients';

export const TABLE_HEAD_CELLS: HeadCell[] = [
  {
    label: 'Client Name',
    value: (client: LiveDataClient) => client.name,
  },
  {
    label: 'Address',
    value: (client: LiveDataClient) => client.address,
  },
  {
    label: 'Send Modes',
    value: (client: LiveDataClient) => client.sendModes.join(', '),
  },
  {
    label: 'Supported Sports',
    value: (client: LiveDataClient) => client.supportedSports.join(', '),
  },
  {
    label: 'isEnabled',
    value: (client: LiveDataClient) => client.isEnabled.toString(),
  },
  {
    label: '',
    value: () => <ActionCell />,
  },
];
