import { FC, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

type ConfirmationDialogProps = {
  isOpen: boolean;
  title?: string;
  body?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmHandler: VoidFunction;
  cancelHandler?: VoidFunction;
  anchorEl: HTMLElement | null;
  placement?: PopperPlacementType;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  '&[data-popper-placement*="left"]': {
    '& .MuiPopper-arrow': {
      right: 0,
      color: theme.palette.background.paper,
      '&::before': {
        content: '""',
        margin: 'auto -0.22rem auto auto',
        display: 'block',
        width: '8px',
        height: '8px',
        boxShadow:
          '0px 2px 0px -1px rgb(0 0 0 / 20%), 1px 1px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%)',
        backgroundColor: 'currentColor',
        transform: 'rotate(-45deg)',
      },
    },
  },
}));

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  title = COMMON_STRING.ARE_YOU_SURE,
  body,
  confirmLabel,
  cancelLabel = COMMON_STRING.CANCEL,
  confirmHandler = () => {},
  cancelHandler = () => {},
  anchorEl,
  placement = 'auto',
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Box>
      <StyledPopper
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        modifiers={[
          { name: 'arrow', enabled: true, options: { element: arrowRef } },
        ]}
      >
        <Paper>
          <ClickAwayListener onClickAway={cancelHandler}>
            <Paper>
              <Box
                data-popper-arrow
                className='MuiPopper-arrow'
                ref={setArrowRef}
              />
              <Box sx={{ p: 1, width: 270 }}>
                <Stack gap={2} sx={{ px: 1 }} direction='row' flexWrap='wrap'>
                  <Typography variant='body1' component='div' fontWeight='bold'>
                    {title}
                  </Typography>
                  {body && <Typography variant='body1'>{body}</Typography>}
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={cancelHandler}
                    aria-label={COMMON_STRING.CANCEL}
                  >
                    {cancelLabel}
                  </Button>
                  <Button
                    size='small'
                    variant='contained'
                    onClick={confirmHandler}
                    aria-label={COMMON_STRING.CONFIRM}
                  >
                    {confirmLabel}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Paper>
      </StyledPopper>
    </Box>
  );
};
