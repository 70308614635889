import useSWR from 'swr';
import { URLS } from '@/utils/env';
import { API } from '@/service/apiRequests/constants';
import { Fetches, fetchData } from '@/service/apiRequests/fetchService';
import { ScorerInfo } from '@/service/hooks/types/Scorers';
import { useToken } from './useToken';

export const useScorerUser = (userId: string) => {
  const token = useToken();
  const queryParams = { ft: userId };
  const { data, error } = useSWR<{ data: ScorerInfo[] }>(
    { token, endpoint: API.USERS.GET, queryParams },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FP_BASE_URL),
  );
  const user = data?.data;

  return { user, error, isLoading: !data && !error };
};
