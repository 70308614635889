import { HeadCell } from '@/components/TableLayout/types';
import { COMMON_STRING, SENT_TYPE_ID } from '@/constants/dictionary';
import { DATE_FORMAT } from '@/utils/constants';
import { formatDate } from '@/utils/formatDate';
import { getDiffMilliseconds } from '@/components/Table/FixtureActionLatency/helpers/getDiffMilliseconds';
import { getActionName } from '@/components/Table/FixtureActionLatency/helpers/getActionName';
import { FixtureAction } from '@/service/hooks/types/FixtureActionLatency';
import { FixtureConfig } from '@/service/hooks/types/FixtureConfig';

export const generateTableCells = (
  fixtureConfig?: FixtureConfig,
): HeadCell[] => {
  return [
    {
      label: 'Fixture SeqNum',
      value: ({ fixtureSeqNum }: FixtureAction) => fixtureSeqNum,
    },
    {
      label: 'Scorer SeqNum',
      value: ({ scorerSeqNum }: FixtureAction) =>
        scorerSeqNum || COMMON_STRING.N_A,
    },
    {
      label: 'Send Type',
      value: ({ sendTypeId }: FixtureAction) =>
        Object.keys(SENT_TYPE_ID).find(
          (key) => SENT_TYPE_ID[key] === sendTypeId,
        ) || COMMON_STRING.N_A,
    },
    {
      label: 'Period',
      value: ({ period }: FixtureAction) => Number(period),
    },
    {
      label: 'Action',
      value: ({ fixtureActionTypeId }: FixtureAction) =>
        getActionName(fixtureActionTypeId, fixtureConfig),
    },
    {
      label: 'APP Created',
      value: ({ createdTimestamp }: FixtureAction) =>
        formatDate(createdTimestamp, DATE_FORMAT.FULL_TIME_AND_ZONE_FORMAT),
    },
    {
      label: 'APP Processed',
      value: ({
        createdTimestamp,
        processedOnDeviceTimestamp,
      }: FixtureAction) =>
        getDiffMilliseconds(createdTimestamp, processedOnDeviceTimestamp),
    },
    {
      label: 'APP Sent',
      value: ({
        processedOnDeviceTimestamp,
        sentFromDeviceTimestamp,
      }: FixtureAction) =>
        getDiffMilliseconds(
          processedOnDeviceTimestamp,
          sentFromDeviceTimestamp,
        ),
    },
    {
      label: 'TCP Received',
      value: ({ sentFromDeviceTimestamp, receivedTimestamp }: FixtureAction) =>
        getDiffMilliseconds(sentFromDeviceTimestamp, receivedTimestamp),
    },
    {
      label: 'TCP Sent',
      value: ({ receivedTimestamp, sentToProcessTimestamp }: FixtureAction) =>
        getDiffMilliseconds(receivedTimestamp, sentToProcessTimestamp),
    },
    {
      label: 'API Received',
      value: ({
        sentToProcessTimestamp,
        receivedToProcessTimestamp,
      }: FixtureAction) =>
        getDiffMilliseconds(sentToProcessTimestamp, receivedToProcessTimestamp),
    },
    {
      label: 'API Processed',
      value: ({
        receivedToProcessTimestamp,
        processedTimestamp,
      }: FixtureAction) =>
        getDiffMilliseconds(receivedToProcessTimestamp, processedTimestamp),
    },
    {
      label: 'DDE Sent',
      value: ({ processedTimestamp }: FixtureAction) =>
        formatDate(processedTimestamp, DATE_FORMAT.FULL_TIME_AND_ZONE_FORMAT),
    },
    {
      label: 'TOTAL APP',
      value: ({ deviceTime }: FixtureAction) =>
        isNaN(deviceTime) ? COMMON_STRING.N_A : deviceTime,
    },
    {
      label: 'TOTAL TCP',
      value: ({ timeSpentInIngest }: FixtureAction) =>
        isNaN(timeSpentInIngest) ? COMMON_STRING.N_A : timeSpentInIngest,
    },
    {
      label: 'TOTAL API',
      value: ({ serverProcessingTime }: FixtureAction) =>
        isNaN(serverProcessingTime) ? COMMON_STRING.N_A : serverProcessingTime,
    },
    {
      label: 'TOTAL Server',
      value: ({ totalServerTimeToProcessed }: FixtureAction) =>
        isNaN(totalServerTimeToProcessed)
          ? COMMON_STRING.N_A
          : totalServerTimeToProcessed,
    },
    {
      label: 'TOTAL',
      value: ({ createdToProcessedTime }: FixtureAction) =>
        isNaN(createdToProcessedTime)
          ? COMMON_STRING.N_A
          : createdToProcessedTime,
    },
  ];
};
