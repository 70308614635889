import { Divider, IconButton, Stack, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import JSONPretty from 'react-json-pretty';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '@/components/Dialog/Dialog';
import { AnalyticsTheme } from '@/context/theme/types';
import { DIALOG } from '@/components/Dialog/constants';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { BaseDialogType } from '@/components/Dialog/types';

type JsonDialogProps<Model> = BaseDialogType & {
  model: Model;
};

export function JsonDialog<Model>({
  isOpen,
  title,
  model,
  onClose,
}: JsonDialogProps<Model>) {
  const theme = useTheme<AnalyticsTheme>();

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={title} maxWidth='sm'>
      <Stack
        sx={{
          background: theme.json.background,
          width: '100%',
          borderRadius: '0.5rem',
          py: 1,
        }}
      >
        <Stack alignItems='end'>
          <Tooltip title='Copy To Clipboard' arrow>
            <IconButton
              aria-label={DIALOG.COPY_JSON_BUTTON_LABEL}
              sx={{ width: 'fit-content' }}
              onClick={() => copyToClipboard(JSON.stringify(model))}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <JSONPretty
          data={model}
          theme={{
            main: `line-height:1.3; color:${theme.json.main};`,
            error: `line-height:1.3; color:${theme.json.red}; background:white;`,
            key: `color:${theme.json.purple};`,
            string: `color:${theme.json.red};`,
            value: `color:${theme.json.blue};`,
            boolean: `color:${theme.json.main};`,
          }}
          style={{ paddingInline: '1rem', overflow: 'auto' }}
        />
      </Stack>
    </Dialog>
  );
}
