import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

export const ActionCell = () => {
  return (
    <>
      <IconButton
        name={COMMON_STRING.BUTTON_MORE}
        size='small'
        aria-label={COMMON_STRING.BUTTON_MORE}
      >
        <MoreVertIcon fontSize='small' />
      </IconButton>
    </>
  );
};
