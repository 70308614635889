export const FILTER_TYPE = {
  SPORT: 'Sport',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
};

export const FILTER_PARAMS = {
  SPORTS: 'sports',
  START_DATE: 'startDateUTC',
  END_DATE: 'endDateUTC',
};
