import { useConnectedScorers } from '@/service/hooks/api/useConnectedScorers';
import { Table } from '@/components/TableLayout/Table';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { TABLE_HEAD_CELLS } from './constants';

export const Scorers = () => {
  const { data: connectedScorers, isLoading } = useConnectedScorers();

  return (
    <Table
      tableName={TAB_NAMES.CONNECTED_SCORERS}
      isLoading={isLoading}
      tableHeadCells={TABLE_HEAD_CELLS}
      data={connectedScorers}
    />
  );
};
