import { Dispatch, SetStateAction, useState } from 'react';
import { Order, PaginatedData } from '@/components/TableLayout/types';
import { TABLE_PAGINATION_ORDER } from '@/components/TableLayout/constants';

export type PaginatedProps = {
  defaultLimit?: number;
  defaultSortBy: string;
  defaultSortOrder?: Order;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
};

export type UsePaginationType = Omit<PaginatedData, 'results'> & {
  sortBy: string;
  sortOrder: Order;
  setLimit: Dispatch<SetStateAction<number>>;
  setPage: Dispatch<SetStateAction<number>>;
  setSortBy: Dispatch<SetStateAction<string>>;
  setSortOrder: Dispatch<SetStateAction<Order>>;
};

export const usePagination = ({
  defaultSortOrder = TABLE_PAGINATION_ORDER.DESC,
  defaultSortBy,
  limit,
  setLimit,
}: PaginatedProps): UsePaginationType => {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  return {
    page,
    sortOrder,
    sortBy,
    limit,
    setLimit,
    setPage,
    setSortOrder,
    setSortBy,
  };
};
