import { API_METHODS } from './constants';

export interface Fetches {
  token: string;
  endpoint: string;
  queryParams?: Record<string, string>;
  method?: string;
}

export const createUrlWithParams = (
  baseUrl: string,
  endpoint: string,
  queryParams?: Record<string, string | string[]>,
) => {
  const url = new URL(baseUrl + endpoint);
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => url.searchParams.append(key, v));
      } else {
        url.searchParams.set(key, value);
      }
    });
  }
  return url.toString();
};

export const fetchData = async (
  { token, endpoint, queryParams, method = API_METHODS.GET }: Fetches,
  baseUrl: string,
  data?: any,
) => {
  const url = createUrlWithParams(baseUrl, endpoint, queryParams);
  try {
    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: method,
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const contentType = res.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return res.json();
    } else {
      return res.text();
    }
  } catch (error: any) {
    console.error('An error occurred:', error.message);
    throw error;
  }
};
