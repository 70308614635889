import { Link } from '@mui/material';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { AnalyticsTheme } from '@/context/theme/types';
import { COMMON_STRING } from '@/constants/dictionary';

export type FixtureCellProp = {
  fixtureId: string;
  url: string;
};

export const FixtureCell: FC<FixtureCellProp> = ({ fixtureId, url }) => {
  const theme = useTheme<AnalyticsTheme>();

  if (!fixtureId) {
    return COMMON_STRING.N_A;
  }

  return (
    <Link
      underline='none'
      href={url + fixtureId}
      target='_blank'
      rel='noopener'
      sx={{
        color: 'inherit',
        '&:hover': {
          color: theme.link.default,
          textDecoration: 'underline',
        },
      }}
    >
      {fixtureId}
    </Link>
  );
};
