import { Stack } from '@mui/material';
import { FC } from 'react';
import { SportsFilter } from '@/components/SportsFilter/SportsFilter';
import { DateFilter } from '@/components/DateFilter/DateFilter';
import { getFilterValue } from '@/components/Filters/utils';
import {
  BaseFilterParams,
  FilterOptionType,
  UsedFilterType,
} from '@/components/Filters/types';
import { FILTER_PARAMS, FILTER_TYPE } from '@/components/Filters/constants';

export interface FiltersProps {
  onSelect: (
    options: FilterOptionType[] | FilterOptionType,
    filterInfo: BaseFilterParams,
  ) => void;
  usedFiltersList: UsedFilterType[];
}

export const Filters: FC<FiltersProps> = ({ onSelect, usedFiltersList }) => {
  return (
    <Stack p={2} gap={2} flex={1}>
      <SportsFilter
        selectedValue={getFilterValue(FILTER_PARAMS.SPORTS, usedFiltersList)}
        onSelect={onSelect}
      />
      <DateFilter
        selectedValue={getFilterValue(
          FILTER_PARAMS.START_DATE,
          usedFiltersList,
        )}
        label={FILTER_TYPE.START_DATE}
        paramName={FILTER_PARAMS.START_DATE}
        onSelect={onSelect}
      />
      <DateFilter
        selectedValue={getFilterValue(FILTER_PARAMS.END_DATE, usedFiltersList)}
        label={FILTER_TYPE.END_DATE}
        paramName={FILTER_PARAMS.END_DATE}
        onSelect={onSelect}
      />
    </Stack>
  );
};
