import { FC } from 'react';
import { Box, Typography } from '@mui/material';

type NoDataProps = {
  text: string;
};

export const NoData: FC<NoDataProps> = ({ text }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Typography variant='h6' textAlign='center'>
        {text}
      </Typography>
    </Box>
  );
};
