import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { App } from '@/App';
import { ROUTE_PATHS } from '@/router/constants';
import { AuthProvider } from '@/context/auth/AuthProvider';
import { tabs } from '@/components/TabsBar/Tabs';
import { TabsBar } from '@/components/TabsBar/TabsBar';

const childRoutes = [
  ...tabs.map(({ path, element }) => ({
    path,
    element,
  })),
  {
    path: '',
    element: <Navigate to={ROUTE_PATHS.CONNECTED_SCORERS} replace />,
  },
];

const routes: RouteObject[] = [
  {
    path: ROUTE_PATHS.OKTA_CALLBACK,
    element: (
      <AuthProvider>
        <LoginCallback />
      </AuthProvider>
    ),
  },
  {
    path: ROUTE_PATHS.HOME,
    element: <App />,
    children: [
      {
        path: '',
        element: <TabsBar />,
        children: childRoutes,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
