import { HeadCell } from '@/components/TableLayout/types';
import { FixtureCell } from '@/components/TableLayout/FixtureCell';
import { ActionCell } from '@/components/TableLayout/ActionCell';
import { SimulationSchedule } from '@/service/hooks/types/SimulationSchedule';
import { URLS } from '@/utils/env';

export const TABLE_CELLS: HeadCell[] = [
  {
    label: 'Id',
    value: ({ id }: SimulationSchedule) => id,
  },
  {
    label: 'Fixture id',
    value: ({ fixtureId }: SimulationSchedule) => (
      <FixtureCell fixtureId={fixtureId} url={URLS.MT_BASE_URL} />
    ),
  },
  {
    label: 'Version',
    value: ({ version }) => version,
  },
  {
    label: 'Max delay milliseconds',
    value: ({ maxDelayMilliseconds }: SimulationSchedule) =>
      maxDelayMilliseconds,
  },
  {
    label: 'Days of week',
    value: ({ scheduleConfig }: SimulationSchedule) =>
      scheduleConfig.scheduleScope.daysOfWeek.join(', '),
  },
  {
    label: 'Start Times',
    value: ({ scheduleConfig }: SimulationSchedule) =>
      scheduleConfig.fixtureKickOffsDateTimeUtc.join(', '),
  },
  {
    label: '',
    value: () => <ActionCell />,
  },
];
