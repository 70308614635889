import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useOktaAuth } from '@okta/okta-react';
import { MouseEvent, useState } from 'react';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { ThemeToggle } from '@/components/ThemeToggle/ThemeToggle';
import { COMMON_STRING } from '@/constants/dictionary';

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1.5, 1.5),
    },
    '& .MuiMenuItem-root.disableHover:hover': {
      backgroundColor: 'inherit',
    },
  }),
);

export const UserMenu = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const username = authState?.idToken?.claims.name ?? '';
  const email = authState?.idToken?.claims.email ?? '';
  const [anchorElUserMenu, setAnchorElUserMenu] = useState<null | HTMLElement>(
    null,
  );

  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => {
    setAnchorElUserMenu(e.currentTarget);
  };

  const handleCloseUserMenu = () => setAnchorElUserMenu(null);

  const logout = () => {
    oktaAuth.signOut();
  };

  return (
    <>
      <Tooltip title='Open settings'>
        <IconButton onClick={handleOpenMenu}>
          <UserAvatar />
        </IconButton>
      </Tooltip>
      <StyledMenu
        sx={{ mt: 1 }}
        anchorEl={anchorElUserMenu}
        keepMounted
        open={Boolean(anchorElUserMenu)}
        onClose={handleCloseUserMenu}
        aria-label={COMMON_STRING.MENU}
      >
        <Box p={1.5}>
          <Typography fontWeight={600}>{username}</Typography>
          <Typography>{email}</Typography>
        </Box>
        <MenuItem className='disableHover' divider>
          <ThemeToggle />
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText>{COMMON_STRING.LOGOUT}</ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  );
};
