import { DATE_FORMAT } from '@/utils/constants';
import { formatDate } from '@/utils/formatDate';
import { COMMON_STRING } from '@/constants/dictionary';
import { getSport } from '@/components/Table/FixtureActionLatency/helpers/getSport';
import { FixtureDetails } from '@/service/hooks/types/FixtureDetails';
import { FixtureActionLatency } from '@/service/hooks/types/FixtureActionLatency';

export const generateBarItems = (
  fixtureDetails?: FixtureDetails,
  latency?: FixtureActionLatency,
) => {
  return [
    {
      label: 'Description',
      value: fixtureDetails ? fixtureDetails.name : COMMON_STRING.N_A,
    },
    {
      label: 'Date',
      value: fixtureDetails
        ? formatDate(fixtureDetails.startDateUTC, DATE_FORMAT.DATE)
        : COMMON_STRING.N_A,
    },
    {
      label: 'Time',
      value: fixtureDetails
        ? formatDate(fixtureDetails.startDateUTC, DATE_FORMAT.HOURS_MINS)
        : COMMON_STRING.N_A,
    },
    {
      label: 'Sport',
      value: fixtureDetails ? getSport(fixtureDetails) : COMMON_STRING.N_A,
    },
    {
      label: 'Competition',
      value: fixtureDetails
        ? fixtureDetails.stage.season.competition.name
        : COMMON_STRING.N_A,
    },
    {
      label: 'Actions',
      value: latency ? latency.fixtureActionsCount : COMMON_STRING.N_A,
    },
    {
      label: 'Average',
      value: latency
        ? latency.summary.avgCreatedToProcessedTime.toFixed(4)
        : COMMON_STRING.N_A,
    },
  ];
};
