import { useContext, useEffect, useState } from 'react';
import {
  BaseFilterParams,
  FilterOptionType,
  UsedFilterType,
} from '@/components/Filters/types';
import { haveFiltersChanged } from '@/components/Filters/utils';
import { UIStateContext } from '@/context/ui/context';

type UseFilterStateProps = {
  onApply: (selectedFilters: UsedFilterType[]) => void;
  usedFiltersList: UsedFilterType[];
};

export const useSelectedFilterState = ({
  onApply,
  usedFiltersList,
}: UseFilterStateProps) => {
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(usedFiltersList);

  const { openedFiltersDrawer, setOpenedFiltersDrawer } =
    useContext(UIStateContext);

  useEffect(() => {
    setSelectedFilters(usedFiltersList);
  }, [openedFiltersDrawer, usedFiltersList]);

  useEffect(() => {
    setIsApplyDisabled(!haveFiltersChanged(usedFiltersList, selectedFilters));
  }, [selectedFilters, usedFiltersList]);

  const onSelect = (
    options: FilterOptionType[] | FilterOptionType,
    filterInfo: BaseFilterParams,
  ) => {
    const selectedOptions = Array.isArray(options) ? options : [options];

    const updatedFilters = selectedFilters.map((filter) =>
      filter.paramName === filterInfo.paramName
        ? { ...filterInfo, options: selectedOptions }
        : filter,
    );

    if (
      !selectedFilters.some(
        (filter) => filter.paramName === filterInfo.paramName,
      )
    ) {
      updatedFilters.push({ ...filterInfo, options: selectedOptions });
    }

    setSelectedFilters(updatedFilters);
  };

  const onFiltersApply = () => {
    onApply(selectedFilters);
    setOpenedFiltersDrawer(null);
    setIsApplyDisabled(true);
  };

  return {
    selectedFilters,
    isApplyDisabled,
    onSelect,
    onFiltersApply,
  };
};
