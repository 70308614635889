export const API_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST',
} as const;

export const API = {
  SCORER: {
    GET: '/Scorer/get-all-connected-scorers',
  },
  LIVE_DATA_CLIENTS: {
    GET: '/v1/LiveDataClients/all-live-data-clients',
    PUT: '/v1/LiveDataClients/update-live-data-client',
  },
  USERS: {
    GET: '/Users',
  },
  SPORTS: {
    GET: '/Sports',
  },
  ANALYTICS: {
    GET: '/v3/Analytics/get-fixture-analytics',
  },
  Schedule: (scheduleId?: string) => {
    const path = '/Schedule';
    return {
      simulationSchedules: {
        GET: `${path}/get-all-simulations-schedule`,
      },
      deleteSchedule: {
        DELETE: `${path}/${scheduleId}`,
      },
    };
  },
  Fixture: (fixtureId?: string) => {
    const path = 'Fixture';
    return {
      fixtureDiagnostics: {
        GET: `/v1/${path}/get-fixture-action-diagnostics/${fixtureId}`,
      },
      fixtureConfig: {
        GET: `/${path}/${fixtureId}/config`,
      },
      fixtureDetails: {
        GET: `/Fixtures/${fixtureId}`,
      },
      fixtureImport: {
        GET: '/Fixtures/FixtureImport',
      },
    };
  },
  Simulation: (simulationAction?: string) => {
    const path = '/Simulator';
    return {
      GET: `${path}/get-simulations`,
      POST: `${path}/${simulationAction}-simulation`,
    };
  },
} as const;
