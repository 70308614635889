import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';
import { DATE_FORMAT } from '@/utils/constants';

type DateTimeProps = DatePickerProps<Date>;

export const DatePicker: FC<DateTimeProps> = ({ ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        {...props}
        format={DATE_FORMAT.DATE}
        sx={{
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderColor: (theme) => theme.palette.action.active,
            },
          },
          '& .MuiInputLabel-root': {
            color: (theme) => theme.palette.action.active,
          },
        }}
      />
    </LocalizationProvider>
  );
};
