import useSWR from 'swr';
import { URLS } from '@/utils/env';
import { API } from '@/service/apiRequests/constants';
import { Fetches, fetchData } from '@/service/apiRequests/fetchService';
import { FixtureConfig } from '@/service/hooks/types/FixtureConfig';
import { useToken } from '@/service/hooks/api/useToken';

export const useFixtureConfig = (fixtureId: string) => {
  const token = useToken();

  const { data: fixtureConfig, error } = useSWR<FixtureConfig>(
    token && fixtureId
      ? { token, endpoint: API.Fixture(fixtureId).fixtureConfig.GET }
      : null,
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FP_MT_BASE_URL),
  );

  return { fixtureConfig, error, isLoading: !fixtureConfig && !error };
};
