export const TABLE = {
  NAME: 'Analytics Table',
  HEADER: 'Analytics Table Header',
  BODY: 'Analytics Table Body',
  PAGINATION: 'Analytics Table Pagination',
  PAGE: {
    FIRST: 'Go to first page',
    PREVIOUS: 'Go to previous page',
    NEXT: 'Go to next page',
    LAST: 'Go to last page',
  },
};

export const TABLE_PAGINATION_OPTIONS = [10, 25, 50, 100];

export const TABLE_PAGINATION_ORDER = {
  DESC: 'desc',
  ASC: 'asc',
} as const;
