import { differenceInMilliseconds, parseISO } from 'date-fns';
import { COMMON_STRING } from '@/constants/dictionary';

export const getDiffMilliseconds = (timeStamp1: string, timeStamp2: string) => {
  if (
    [timeStamp1, timeStamp2].includes(COMMON_STRING.DEFAULT_TIMESTAMP) ||
    isNaN(differenceInMilliseconds(parseISO(timeStamp2), parseISO(timeStamp1)))
  ) {
    return COMMON_STRING.N_A;
  }

  return differenceInMilliseconds(parseISO(timeStamp2), parseISO(timeStamp1));
};
