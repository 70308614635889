import { PaletteMode } from '@mui/material';
import { LIGHT_PALETTE_MODE } from './constants';

export const THEME_MODE_ITEM_NAME = 'analytics-theme-mode';

export const persistThemeMode = (value: PaletteMode) => {
  localStorage.setItem(THEME_MODE_ITEM_NAME, value);
};

export const getPersistedThemeMode = () =>
  localStorage.getItem(THEME_MODE_ITEM_NAME)
    ? (localStorage.getItem(THEME_MODE_ITEM_NAME) as PaletteMode)
    : LIGHT_PALETTE_MODE;
