import {
  PaletteMode,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  DarkMode,
  DarkModeOutlined,
  LightMode,
  LightModeOutlined,
} from '@mui/icons-material';
import { MouseEvent } from 'react';
import { useThemeContext } from '@/context/theme/context';
import {
  DARK_PALETTE_MODE,
  LIGHT_PALETTE_MODE,
} from '@/context/theme/constants';

export const ThemeToggle = () => {
  const { mode, toggleThemeMode } = useThemeContext();

  const onChange = (_: MouseEvent<HTMLElement>, value: PaletteMode) => {
    if (!value) return;
    toggleThemeMode(value);
  };

  return (
    <Stack flexGrow={1}>
      <Typography
        variant='caption'
        component={'p'}
        gutterBottom
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        MODE
      </Typography>
      <ToggleButtonGroup
        color='primary'
        size='small'
        value={mode}
        exclusive
        onChange={onChange}
        fullWidth
      >
        <ToggleButton value={LIGHT_PALETTE_MODE} sx={{ gap: 0.5 }}>
          {mode === LIGHT_PALETTE_MODE ? <LightMode /> : <LightModeOutlined />}
          {LIGHT_PALETTE_MODE}
        </ToggleButton>
        <ToggleButton value={DARK_PALETTE_MODE} sx={{ gap: 0.5 }}>
          {mode === DARK_PALETTE_MODE ? <DarkMode /> : <DarkModeOutlined />}
          {DARK_PALETTE_MODE}
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
