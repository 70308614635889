import { SnackbarProvider } from 'notistack';
import { FC, PropsWithChildren } from 'react';
import { SnackbarCloseAction } from '@/components/SnackbarActions/SnackBarCloseAction';

const SNACKBAR_DISAPPEAR_AFTER_DURATION = 5000;

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider
      autoHideDuration={SNACKBAR_DISAPPEAR_AFTER_DURATION}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(snackbarId) => <SnackbarCloseAction snackbarId={snackbarId} />}
    >
      {children}
    </SnackbarProvider>
  );
};
