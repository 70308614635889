import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { FC } from 'react';
import { ContextMenuItem } from '@/components/TableLayout/types';

export type ContextMenuProps = {
  items?: ContextMenuItem[];
};

export const ContextMenu: FC<ContextMenuProps> = ({ items }) => {
  return (
    <Paper sx={{ width: 'fit-content', maxWidth: '100%' }}>
      <MenuList variant='menu' sx={{ py: 0 }}>
        {items &&
          items.map(({ onClick, Icon, label, ref }, index, { length }) => (
            <MenuItem
              ref={ref}
              key={index}
              divider={index < length - 1}
              onClick={onClick}
            >
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
      </MenuList>
    </Paper>
  );
};
