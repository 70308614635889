import { UsedFilterType } from '@/components/Filters/types';

export const getFilterValue = (
  paramName: string,
  usedFiltersList?: UsedFilterType[],
) => {
  if (!usedFiltersList || !usedFiltersList.length) return [];

  const filter = usedFiltersList.find(
    (filter) => filter.paramName === paramName,
  );
  return filter ? filter.options : [];
};

export const haveFiltersChanged = (
  initialFilters: UsedFilterType[],
  currentFilters: UsedFilterType[],
) => {
  const initialString = JSON.stringify(initialFilters);
  const currentString = JSON.stringify(currentFilters);

  return initialString !== currentString;
};
