import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useToken } from '@/service/hooks/api/useToken';
import { API, API_METHODS } from '@/service/apiRequests/constants';
import { fetchData, Fetches } from '@/service/apiRequests/fetchService';
import { URLS } from '@/utils/env';
import { LiveDataClient } from '@/service/hooks/types/LiveDataClients';

export const useLiveDataClients = () => {
  const token = useToken();
  const {
    data: liveDataClients,
    error,
    mutate,
  } = useSWR<LiveDataClient[]>(
    { token, endpoint: API.LIVE_DATA_CLIENTS.GET },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FAB_BASE_URL),
  );

  const { trigger: putLiveDataClient } = useSWRMutation(
    {
      token,
      endpoint: API.LIVE_DATA_CLIENTS.PUT,
      method: API_METHODS.PUT,
    },
    (fetchParams: Fetches, data) =>
      fetchData(fetchParams, URLS.FAB_BASE_URL, data.arg),
  );

  return {
    liveDataClients,
    error,
    isLoading: !liveDataClients && !error,
    putLiveDataClient,
    mutate,
  };
};
