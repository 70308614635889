export const COMMON_STRING = {
  APP_NAME: 'Fastpath Analytics',
  SHORT_APP_NAME: 'FA',
  LOGOUT: 'Logout',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
  APPLY: 'Apply',
  COPY_ID_LABEL: 'Copy ID To Clipboard',
  BUTTON_MORE: 'More',
  ARE_YOU_SURE: 'Are you sure?',
  N_A: 'N/A',
  COPY: 'Copy',
  CLOSE: 'Close',
  FILTERS: 'Filters',
  FILTERS_FORM: 'Filters Form',
  UNDO_FILTER: 'Undo Filter',
  CLEAR_FILTERS: 'Clear filters',
  MENU: 'Menu',
  BATTERY_CHARGING: 'Charging',
  FIXTURE_ID: 'Fixture ID',
  QUERY_FIXTURE_ID: 'fixtureId',
  PENDING: 'Pending',
  UPDATED: 'Updated',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',
  CONFIRMED: 'Confirmed',
  DEFAULT_TIMESTAMP: '0001-01-01T00:00:00',
  START: 'Start',
  STOP: 'Stop',
  RESET: 'Reset',
};

export const STATUS = {
  [COMMON_STRING.BATTERY_CHARGING]: 255,
};

export const FIXTURE_STATUS = {
  SCHEDULED: 'scheduled',
  LIVE: 'live',
  COMPLETED: 'completed',
  POSTPONED: 'postponed',
  ABANDONED: 'abandoned',
  CANCELLED: 'cancelled',
  SUSPENDED: 'suspended',
  UNVERIFIED: 'unverified',
} as const;

export const SENT_TYPE_ID = {
  [COMMON_STRING.PENDING]: 1,
  [COMMON_STRING.UPDATED]: 2,
  [COMMON_STRING.CANCELLED]: 3,
  [COMMON_STRING.DELETED]: 4,
  [COMMON_STRING.CONFIRMED]: 5,
};

export const SUCCESS_STRING = {
  CLIENT_ACTION: (name?: string, isEnabled?: boolean) =>
    `Client '${name}' was ${!isEnabled ? 'enabled' : 'disabled'}`,
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  SIMULATION_SCHEDULE_DELETED: (id?: string) =>
    `Simulation schedule id ${id} has been deleted`,
  SIMULATION: (fixtureId?: string, simulationAction?: string) =>
    `Simulation id ${fixtureId} has been ${simulationAction?.toLowerCase()}`,
};

export const CONFIRMATION_STRING = {
  TITLE: {
    SIMULATION_SCHEDULE: (id?: string) => `Simulation schedule id ${id}`,
    SIMULATION: (fixtureId?: string) => `Simulation fixtureId ${fixtureId}`,
  },
  BODY: {
    SIMULATION_SCHEDULE:
      'Are you sure you want to delete this simulation schedule?',
    LIVE_DATA_CLIENT: (isEnabled?: boolean) =>
      `Are you sure you want to ${
        isEnabled ? 'disable' : 'enable'
      } this client?`,
    SIMULATION: (simulationAction?: string) =>
      `Are you sure you want to ${simulationAction?.toLowerCase()} this simulation?`,
  },
};

export const ERROR_STRING = {
  FIXTURE_NOT_FOUND: 'Fixture could not be found',
  INCORRECT_FIXTURE: 'Fixture id format is incorrect',
};

export const NO_DATA_STRING = {
  NO_DATA_TO_DISPLAY: (itemsName: string) =>
    `There are no ${itemsName.toLowerCase()} yet`,
  NO_ACTION_LATENCY:
    'Please supply a fixture id in the search box above to see action latencies',
  FIXTURE_TOOLTIP: 'Fixture Id must be 36 symbols',
  NO_OPTIONS: 'No options available',
  UNDER_MAINTEINANCE:
    'This page is currently disabled due to performance issues. We hope to re-enable it in June 2024',
};

export const DICTIONARY = {
  SUCCESS: SUCCESS_STRING,
  NO_DATA: NO_DATA_STRING,
  ERROR: ERROR_STRING,
};
