import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { URLS } from '@/utils/env';
import { Fetches, fetchData } from '@/service/apiRequests/fetchService';
import { SimulationSchedule } from '@/service/hooks/types/SimulationSchedule';
import { API, API_METHODS } from '@/service/apiRequests/constants';
import { useToken } from './useToken';

export const useSimulationSchedules = (scheduleId?: string) => {
  const token = useToken();
  const {
    data: simulationSchedules,
    error,
    mutate,
  } = useSWR<SimulationSchedule[]>(
    { token, endpoint: API.Schedule().simulationSchedules.GET },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FS_BASE_URL),
  );

  const { trigger: deleteSimulationSchedule } = useSWRMutation(
    {
      token,
      endpoint: API.Schedule(scheduleId).deleteSchedule.DELETE,
      method: API_METHODS.DELETE,
    },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FS_BASE_URL),
  );

  return {
    simulationSchedules,
    error,
    isLoading: !simulationSchedules && !error,
    deleteSimulationSchedule,
    mutate,
  };
};
