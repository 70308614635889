import {
  Button,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useContext, PropsWithChildren } from 'react';
import { DIALOG } from '@/components/Dialog/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { PANEL, UIStateContext } from '@/context/ui/context';

export interface FiltersDrawerProps {
  isApplyDisabled: boolean;
  onFiltersApply: () => void;
}

export const FiltersDrawer: FC<PropsWithChildren<FiltersDrawerProps>> = ({
  children,
  onFiltersApply,
  isApplyDisabled,
}) => {
  const { openedFiltersDrawer, setOpenedFiltersDrawer } =
    useContext(UIStateContext);

  return (
    <Drawer
      anchor='right'
      open={openedFiltersDrawer === PANEL.FILTERS}
      aria-label={COMMON_STRING.FILTERS_FORM}
      onClose={() => setOpenedFiltersDrawer(null)}
    >
      <Stack
        height='100%'
        divider={<Divider orientation='horizontal' flexItem />}
        width={480}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <DialogTitle sx={{ p: 2, fontWeight: 'bold' }}>
            {COMMON_STRING.FILTERS}
          </DialogTitle>
          <IconButton
            onClick={() => setOpenedFiltersDrawer(null)}
            size='small'
            sx={{
              margin: 1,
              color: (theme) => theme.palette.grey[500],
            }}
            aria-label={DIALOG.CLOSE_BUTTON_LABEL}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {children}
        <Stack p={2} gap={1} direction='row'>
          <Button onClick={() => setOpenedFiltersDrawer(null)}>
            {COMMON_STRING.CANCEL}
          </Button>
          <Button
            variant='contained'
            disabled={isApplyDisabled}
            onClick={onFiltersApply}
            sx={{ flex: 1 }}
          >
            {COMMON_STRING.APPLY}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
