import { useState } from 'react';
import { isValid } from 'date-fns';
import { formatDate } from '@/utils/formatDate';
import { DATE_FORMAT } from '@/utils/constants';
import { DatePicker } from '@/components/DatePicker/DatePicker';
import { DateFilterProps, FilterOptionType } from '@/components/Filters/types';

export function DateFilter({
  onSelect,
  paramName,
  label,
  selectedValue,
}: DateFilterProps) {
  const [value, setValue] = useState(
    selectedValue && Array.isArray(selectedValue) && selectedValue.length
      ? new Date(selectedValue[0]?.id as string)
      : null,
  );

  const handleChange = (date: Date | null) => {
    if (!isValid(date) || !date) return;

    setValue(date);

    const option = {
      id: date.toISOString(),
      name: formatDate(date.toString(), DATE_FORMAT.DATE),
    } as FilterOptionType;

    if (!onSelect) return;
    onSelect(option, { label, paramName });
  };

  return <DatePicker label={label} onChange={handleChange} value={value} />;
}
