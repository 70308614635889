import { Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from '@/context/auth/AuthProvider';
import { Protected } from '@/components/Protected/Protected';
import { Layout } from '@/components/Layout/Layout';
import { AnalyticsThemeProvider } from '@/context/theme/context';
import { NotificationsProvider } from '@/context/notifications/NotificationsProvider';
import { UIStateProvider } from '@/context/ui/UIStateProvider';

export function App() {
  return (
    <NotificationsProvider>
      <AnalyticsThemeProvider>
        <CssBaseline />
        <AuthProvider>
          <Protected>
            <UIStateProvider>
              <Layout>
                <Outlet />
              </Layout>
            </UIStateProvider>
          </Protected>
        </AuthProvider>
      </AnalyticsThemeProvider>
    </NotificationsProvider>
  );
}
