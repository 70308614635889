import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PaletteMode, ThemeProvider } from '@mui/material';
import {
  getPersistedThemeMode,
  persistThemeMode,
} from '@/context/theme/memorizeTheme';
import { AnalyticsTheme } from '@/context/theme/types';
import { getAnalyticsTheme } from '@/context/theme/theme';
import { LIGHT_PALETTE_MODE } from '@/context/theme/constants';

export type AnalyticsContextThemeType = {
  theme: AnalyticsTheme;
  toggleThemeMode: (value: PaletteMode) => void;
  mode: PaletteMode;
};

export const AnalyticsThemeContext = createContext<AnalyticsContextThemeType>({
  theme: getAnalyticsTheme(LIGHT_PALETTE_MODE),
  toggleThemeMode: () => {},
  mode: LIGHT_PALETTE_MODE,
});

export const useThemeContext = () => useContext(AnalyticsThemeContext);

export const AnalyticsThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>(getPersistedThemeMode());
  const theme = useMemo(() => getAnalyticsTheme(mode), [mode]);

  const toggleThemeMode = (value: PaletteMode) => {
    setMode(value);
  };

  useEffect(() => {
    persistThemeMode(mode);
  }, [mode]);

  return (
    <AnalyticsThemeContext.Provider
      value={{
        theme,
        toggleThemeMode,
        mode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AnalyticsThemeContext.Provider>
  );
};
