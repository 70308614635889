import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@/router/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { UIStateContext } from '@/context/ui/context';
import { tabs } from './Tabs';

export const TabsBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { fixtureId } = useContext(UIStateContext);

  const activeTab =
    tabs.find(({ path }) => pathname.startsWith(path))?.path || tabs[0].path;

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    if (fixtureId && newValue === ROUTE_PATHS.FIXTURE_ACTION_LATENCY) {
      navigate({
        pathname: newValue,
        search: `${COMMON_STRING.QUERY_FIXTURE_ID}=${fixtureId}`,
      });
    } else {
      navigate(newValue);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', flex: '0 0 auto' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            textColor='inherit'
            value={activeTab}
            onChange={handleChange}
            sx={{
              '& .Mui-selected': {
                color: ({ palette }) => palette.primary.main,
              },
            }}
          >
            {tabs.map(({ label, path }) => (
              <Tab
                key={label}
                label={label}
                value={path}
                sx={{ textTransform: 'unset', fontWeight: 'bold' }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Outlet />
    </>
  );
};
