import { Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { FilterOptionType } from '@/components/Filters/types';
import { NO_DATA_STRING } from '@/constants/dictionary';

interface ChipSelectFilterProps {
  label?: string;
  options?: FilterOptionType[];
  isLoading: boolean;
  selectedValue?: FilterOptionType[];
  onClose?: () => void;
  onChange: (options: FilterOptionType[]) => void;
}

export const ChipSelectFilter: FC<ChipSelectFilterProps> = ({
  label,
  selectedValue = [],
  options,
  isLoading,
  onChange,
}) => {
  const [selected, setSelected] = useState(selectedValue);

  const isOptionSelected = (option: FilterOptionType) =>
    selected.some((selectedOption) => selectedOption.id === option.id);

  const handleClick = (option: FilterOptionType) => {
    const isSelected = isOptionSelected(option);

    const selectedFilters = isSelected
      ? selected.filter(({ id }) => id !== option.id)
      : [...selected, option];

    setSelected(selectedFilters);

    onChange(selectedFilters);
  };

  return (
    <Stack direction='column' width='100%'>
      <Typography>{label}</Typography>
      <Stack direction='row' flexWrap='wrap' gap={0.75} py={0.75}>
        {isLoading ? (
          <CircularProgress />
        ) : options?.length ? (
          options.map((option) => (
            <Chip
              variant='outlined'
              key={Number(option.id)}
              label={option.name}
              onClick={() => handleClick(option)}
              color={isOptionSelected(option) ? 'primary' : 'default'}
              sx={{
                height: 26,
                '& .MuiChip-label': {
                  px: 1.05,
                  fontSize: 12,
                },
              }}
            />
          ))
        ) : (
          <Typography variant='h6'>{NO_DATA_STRING.NO_OPTIONS}</Typography>
        )}
      </Stack>
    </Stack>
  );
};
