import { Avatar } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { FC, useMemo } from 'react';

export const UserAvatar: FC = () => {
  const { authState } = useOktaAuth();
  const user = authState?.idToken?.claims.name ?? '';

  const getUserInitials = useMemo(() => {
    if (!user) return;
    const [firstName, lastName] = user.split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }, [user]);

  return <Avatar>{getUserInitials}</Avatar>;
};
