import { FC, PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { Navbar } from '@/components/Navbar/Navbar';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          flex: '0 0 auto',
        }}
      >
        <Navbar />
      </Box>
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};
