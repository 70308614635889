import { ROUTE_PATHS } from '@/router/constants';
import { FixtureImport } from '@/components/Table/FixtureImport/FixtureImport';
import { Scorers } from '@/components/Table/Scorers/Scorers';
import { FixtureActionsLatency } from '@/components/Table/FixtureActionLatency/FixtureActionsLatency';
import { LiveDataClients } from '@/components/Table/LiveDataClients/LiveDataClients';
import { SimulationSchedules } from '@/components/Table/SimulationSchedules/SimulationSchedules';
import { Simulations } from '@/components/Table/Simulations/Simulations';
// import { AverageLatencies } from '@/components/Table/AverageLatencies/AverageLatencies';
import { NO_DATA_STRING } from '@/constants/dictionary';
import { NoData } from '../TableLayout/NoData';
import { TAB_NAMES } from './constants';

export const tabs = [
  {
    label: TAB_NAMES.CONNECTED_SCORERS,
    path: ROUTE_PATHS.CONNECTED_SCORERS,
    element: <Scorers />,
  },
  {
    label: TAB_NAMES.LIVE_DATA_CLIENTS,
    path: ROUTE_PATHS.LIVE_DATA_CLIENTS,
    element: <LiveDataClients />,
  },
  {
    label: TAB_NAMES.FIXTURE_ACTION_LATENCY,
    path: ROUTE_PATHS.FIXTURE_ACTION_LATENCY,
    element: <FixtureActionsLatency />,
  },
  {
    label: TAB_NAMES.FIXTURE_IMPORT,
    path: ROUTE_PATHS.FIXTURE_IMPORT,
    element: <FixtureImport />,
  },
  {
    label: TAB_NAMES.SIMULATION_SCHEDULES,
    path: ROUTE_PATHS.SIMULATION_SCHEDULES,
    element: <SimulationSchedules />,
  },
  {
    label: TAB_NAMES.SIMULATIONS,
    path: ROUTE_PATHS.SIMULATIONS,
    element: <Simulations />,
  },
  {
    label: TAB_NAMES.AVERAGE_LATENCIES,
    path: ROUTE_PATHS.AVERAGE_LATENCIES,
    element: <NoData text={NO_DATA_STRING.UNDER_MAINTEINANCE} />,
  },
  // !!! DO NOT UNCOMMENT UNTIL BackEnd FINISHED ITS INVESTIGATION
  // {
  //   label: TAB_NAMES.AVERAGE_LATENCIES,
  //   path: ROUTE_PATHS.AVERAGE_LATENCIES,
  //   element: <AverageLatencies />,
  // },
];
