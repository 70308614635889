import { SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export interface SnackbarCopyToClipboardProps {
  snackbarId: SnackbarKey;
}

export const SnackbarCloseAction: FC<SnackbarCopyToClipboardProps> = ({
  snackbarId,
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      color='inherit'
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <Close fontSize='small' />
    </IconButton>
  );
};
