import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Close, FilterList, FilterListOff, Undo } from '@mui/icons-material';
import { ChangeEvent, useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { COMMON_STRING } from '@/constants/dictionary';
import { UsedFilterList } from '@/components/UsedFilterList/UsedFilterList';
import { PANEL, UIStateContext } from '@/context/ui/context';
import { usePersistableFilters } from '@/service/hooks/table/usePersistableFilters';

interface FiltersBarProps {
  tableName: string;
}

export const FiltersBar = ({ tableName }: FiltersBarProps) => {
  const {
    tableFilters,
    setFreetextSearch,
    clearFilters,
    onFilterRemove,
    undoFilters,
  } = usePersistableFilters(tableName);
  const { setOpenedFiltersDrawer } = useContext(UIStateContext);

  return (
    <Stack direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Stack p={0.5} direction='row' gap={0.5} alignItems='center' flex={1}>
        <TextField
          sx={{ fontSize: 12, width: 320 }}
          value={tableFilters?.ft || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFreetextSearch(e.target.value)
          }
          placeholder='Search...'
          InputProps={{
            sx: {
              height: 36,
            },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setFreetextSearch('')} edge='end'>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack flex={1}>
          <UsedFilterList
            usedFiltersList={tableFilters.filters}
            onFilterRemove={onFilterRemove}
          />
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center'>
        {!!tableFilters.filters.length && (
          <Tooltip title={COMMON_STRING.CLEAR_FILTERS}>
            <IconButton
              color='primary'
              aria-label={COMMON_STRING.CLEAR_FILTERS}
              onClick={clearFilters}
            >
              <FilterListOff fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        {!!tableFilters.filters.length && (
          <Tooltip title={COMMON_STRING.UNDO_FILTER}>
            <IconButton
              color='primary'
              aria-label={COMMON_STRING.UNDO_FILTER}
              onClick={undoFilters}
            >
              <Undo fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={COMMON_STRING.FILTERS}>
          <IconButton
            color='primary'
            name={COMMON_STRING.FILTERS}
            aria-label={COMMON_STRING.FILTERS}
            onClick={() => setOpenedFiltersDrawer(PANEL.FILTERS)}
          >
            <FilterList fontSize='small' />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
