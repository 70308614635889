export const ENVIRONMENTS = {
  LOCAL: 'development',
  DEV: 'dev',
  STAGING: 'staging',
  SANDBOX: 'sandbox',
  PROD: 'production',
} as const;

export const DISPLAY_ENV: string[] = [
  ENVIRONMENTS.STAGING,
  ENVIRONMENTS.DEV,
  ENVIRONMENTS.SANDBOX,
];

export type Environment = ConstType<typeof ENVIRONMENTS>;
