import { COMMON_STRING } from '@/constants/dictionary';
import { FixtureConfig } from '@/service/hooks/types/FixtureConfig';

export const getActionName = (
  actionTypeId: number,
  fixtureConfig?: FixtureConfig,
) => {
  if (!fixtureConfig || !actionTypeId) return COMMON_STRING.N_A;

  const actionButton = fixtureConfig.fixtureOptions.actionButtons.find(
    ({ actionType }) => actionType.id === actionTypeId,
  );
  if (actionButton) return actionButton.actionType.name;

  const staticActionType = fixtureConfig.staticFixtureActions.find(
    ({ id }) => id === actionTypeId,
  );

  if (staticActionType) return staticActionType.name;

  return actionTypeId.toString();
};
