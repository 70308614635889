import { FC } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BaseDialogType } from '@/components/Dialog/types';
import { DIALOG } from './constants';

type DialogProps = BaseDialogType & {
  fullWidth?: MuiDialogProps['fullWidth'];
  maxWidth?: MuiDialogProps['maxWidth'];
  sx?: MuiDialogProps['sx'];
};

export const Dialog: FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  sx,
  children,
  fullWidth = true,
  maxWidth = 'sm',
}) => {
  return (
    <MuiDialog
      open={isOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-label={DIALOG.NAME}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <DialogTitle sx={{ p: 2 }}>{title}</DialogTitle>
        <IconButton
          onClick={onClose}
          size='small'
          sx={{
            margin: 1,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={DIALOG.CLOSE_BUTTON_LABEL}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          pt: 0,
          ...sx,
        }}
      >
        {children}
      </DialogContent>
    </MuiDialog>
  );
};
