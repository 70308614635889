import { FixtureImportType } from '@/service/hooks/types/FixtureImport';
import { ValidationError } from '@/service/apiRequests/ApiError';

export const parseFixtureImportErrors = (
  errors: FixtureImportType['errors'],
) => {
  if (!errors) return '';

  try {
    const parsedErrors = JSON.parse(errors) as ValidationError[];

    if (!parsedErrors) return '';

    const errorMessages = parsedErrors.map((error) => error.ErrorMessage);

    return errorMessages.join(', ');
  } catch (e) {
    return errors;
  }
};
