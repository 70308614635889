import { useContext } from 'react';
import { Filters, UsedFilterType } from '@/components/Filters/types';
import { UIStateContext } from '@/context/ui/context';

export type UsePersistableFiltersProps = {
  tableFilters: Filters;
  clearFilters: () => void;
  setFilters: (newFilters: UsedFilterType[]) => void;
  undoFilters: () => void;
  onFilterRemove: (filterIndex: number, paramIndex: number) => void;
  setFreetextSearch: (ft: string) => void;
};

export const usePersistableFilters = (
  tableName: string,
): UsePersistableFiltersProps => {
  const { tableFilters, setTableFilters } = useContext(UIStateContext);

  const setFilters = (newFilters: UsedFilterType[]) => {
    setTableFilters((prevFilters) => ({
      ...prevFilters,
      [tableName]: {
        filters: newFilters,
        filtersHistory: [...prevFilters[tableName].filtersHistory, newFilters],
        ft: prevFilters[tableName].ft,
      },
    }));
  };

  const clearFilters = () => {
    setTableFilters((prevFilters) => ({
      ...prevFilters,
      [tableName]: {
        filters: [],
        filtersHistory: [],
        ft: prevFilters[tableName].ft,
      },
    }));
  };

  const undoFilters = () => {
    setTableFilters((prevFilters) => {
      const newHistory = [...prevFilters[tableName].filtersHistory];

      newHistory.pop();

      const prevHistoryFilters = newHistory.at(-1) || [];

      return {
        ...prevFilters,
        [tableName]: {
          filters: prevHistoryFilters,
          filtersHistory: newHistory,
          ft: prevFilters[tableName].ft,
        },
      };
    });
  };

  const onFilterRemove = (paramIndex: number, filterIndex: number) => {
    setTableFilters((prevFilters) => {
      const newFilters = [...prevFilters[tableName].filters];
      const newOptions = [...newFilters[paramIndex].options];

      newOptions.splice(filterIndex, 1);

      if (newOptions.length > 0) {
        newFilters[paramIndex] = {
          ...newFilters[paramIndex],
          options: newOptions,
        };
      } else {
        newFilters.splice(paramIndex, 1);
      }

      return {
        ...prevFilters,
        [tableName]: {
          filters: newFilters,
          filtersHistory: [
            ...prevFilters[tableName].filtersHistory,
            newFilters,
          ],
          ft: prevFilters[tableName].ft,
        },
      };
    });
  };

  const setFreetextSearch = (ft: string) => {
    setTableFilters((prevFilters) => ({
      ...prevFilters,
      [tableName]: {
        ...prevFilters[tableName],
        ft,
      },
    }));
  };

  return {
    tableFilters: tableFilters[tableName],
    undoFilters,
    clearFilters,
    onFilterRemove,

    setFilters,
    setFreetextSearch,
  };
};
