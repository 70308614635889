import { HeadCell } from '@/components/TableLayout/types';
import { FixtureImportType } from '@/service/hooks/types/FixtureImport';
import { ActionCell } from '@/components/TableLayout/ActionCell';
import { formatDate } from '@/utils/formatDate';
import { COMMON_STRING } from '@/constants/dictionary';
import { camelCaseToSpaceSeparated } from '@/utils/camelCaseToSeparated';
import { DATE_FORMAT } from '@/utils/constants';
import { parseFixtureImportErrors } from '@/components/Table/FixtureImport/helpers/parseFixtureImportErrors';

export const SORT_ATTRIBUTES = {
  REQUEST_TIMESTAMP: 'RequestTimestamp',
  PROVIDER_NAME: 'ProviderName',
  COMPETITION_NAME: 'CompetitionName',
  RESPONSE_CODE: 'ResponseCode',
  AVERAGE_LATENCY: 'AverageLatency',
  START_DATE: 'StartDate',
};

export const TABLE_HEAD_CELLS: HeadCell[] = [
  {
    label: 'Requested',
    value: ({ requestStartTimestamp }: FixtureImportType) =>
      requestStartTimestamp
        ? formatDate(requestStartTimestamp, DATE_FORMAT.DATE_TIME_ZONE_FORMAT)
        : COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.REQUEST_TIMESTAMP,
  },
  {
    label: 'User',
    value: ({ username }: FixtureImportType) => username || COMMON_STRING.N_A,
  },
  {
    label: 'Provider',
    value: ({ providerName }: FixtureImportType) =>
      providerName
        ? camelCaseToSpaceSeparated(providerName)
        : COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.PROVIDER_NAME,
  },
  {
    label: 'Competition',
    value: ({ competitionName }: FixtureImportType) =>
      competitionName
        ? camelCaseToSpaceSeparated(competitionName)
        : COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.COMPETITION_NAME,
  },
  {
    label: 'Season',
    value: ({ seasonName }: FixtureImportType) =>
      seasonName ? camelCaseToSpaceSeparated(seasonName) : COMMON_STRING.N_A,
  },
  {
    label: 'Stage',
    value: ({ stageName }: FixtureImportType) =>
      stageName ? camelCaseToSpaceSeparated(stageName) : COMMON_STRING.N_A,
  },
  {
    label: 'Group',
    value: ({ groupName }: FixtureImportType) => groupName || COMMON_STRING.N_A,
  },
  {
    label: 'Round',
    value: ({ roundName }: FixtureImportType) => roundName || COMMON_STRING.N_A,
  },
  {
    label: 'Response',
    value: ({ responseCode }: FixtureImportType) =>
      responseCode || COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.RESPONSE_CODE,
  },
  {
    label: 'Errors',
    value: ({ errors }: FixtureImportType) => parseFixtureImportErrors(errors),
    sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '15em',
    },
  },
  {
    label: '',
    value: ({ errors }: FixtureImportType) => (errors ? <ActionCell /> : null),
  },
];
