import {
  Box,
  CircularProgress,
  Popover,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TablePagination,
} from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import DataObject from '@mui/icons-material/DataObject';
import { ViewportList } from 'react-viewport-list';
import { TableRow } from '@/components/TableLayout/TableRow';
import { findRowInPath } from '@/components/TableLayout/utils';
import {
  ContextMenuItem,
  DialogState,
  DialogType,
  HeadCell,
} from '@/components/TableLayout/types';
import { NoData } from '@/components/TableLayout/NoData';
import { COMMON_STRING, DICTIONARY } from '@/constants/dictionary';
import {
  TABLE,
  TABLE_PAGINATION_OPTIONS,
} from '@/components/TableLayout/constants';
import { UsePaginationType } from '@/service/hooks/table/usePagination';
import { JsonDialog } from '../Dialog/JsonDialog';
import { TableHeader } from './TableHeader';
import { ContextMenu } from './ContextMenu';

interface TableProps<Data> {
  tableName: string;
  rowData?: Data | null;
  optionalRowData?: Data[keyof Data];
  isLoading: boolean;
  tableHeadCells: HeadCell[];
  menuItems?: ContextMenuItem[];
  data?: Data[];
  noDataText?: string;
  pagination?: UsePaginationType;
  totalRowsCount?: number;

  setRowData?: Dispatch<SetStateAction<Data | null>>;
}

export const TableCellStyled = ({ children, sx, ...rest }: TableCellProps) => {
  return (
    <TableCell
      component='div'
      sx={{
        padding: (theme) => theme.spacing(1, 0, 1, 1),
        textAlign: 'start',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </TableCell>
  );
};

export function Table<Data>({
  tableName,
  tableHeadCells,
  data,
  menuItems,
  isLoading,
  rowData,
  optionalRowData,
  noDataText = DICTIONARY.NO_DATA.NO_DATA_TO_DISPLAY(tableName),
  pagination,
  totalRowsCount,

  setRowData,
}: TableProps<Data>) {
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [actionDialog, setActionDialog] = useState<DialogState<Data> | null>();
  const {
    page,
    setPage,
    limit,
    setLimit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
  } = pagination || {};
  const ref = useRef<HTMLDivElement | null>(null);

  const openContextMenu = (event: MouseEvent<HTMLElement>) => {
    setContextMenuPosition({ top: event.pageY + 5, left: event.pageX });
  };

  const closeContextMenu = () => {
    if (!setRowData) return;

    setRowData(null);
  };

  const onTableClick = (event: MouseEvent<HTMLTableElement>): void => {
    if (!setRowData) return;

    const path = event.nativeEvent.composedPath() as HTMLElement[];
    const button = path.find(({ tagName }) => tagName === 'BUTTON') as
      | HTMLButtonElement
      | undefined;
    if (!button) return;

    const clickedRow = findRowInPath(path);
    if (!clickedRow) {
      return;
    }

    const rowObject = data?.find((row) => JSON.stringify(row) === clickedRow);

    if (!rowObject) {
      return;
    }

    setRowData(rowObject);

    if (button.name === COMMON_STRING.BUTTON_MORE) {
      openContextMenu(event);
    }
  };

  const openJsonDialog = (
    dialog?: DialogType | null,
    model?: Data | Data[keyof Data] | null,
    title?: string,
  ) => {
    if (!model) return;

    setActionDialog({
      props: {
        isOpen: true,
        onClose: () => setActionDialog(null),
        model,
        title,
      },
      dialog,
    });
  };

  const newMenuItems = menuItems && [
    ...menuItems,
    {
      Icon: DataObject,
      label: 'Show JSON',
      onClick: () => {
        openJsonDialog(JsonDialog, optionalRowData ?? rowData, 'Show JSON');
        closeContextMenu();
      },
    },
  ];

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (!setPage) return;

    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (!setPage || !setLimit) return;

    setLimit(+event.target.value);

    setPage(1);
  };

  const handleRequestSort = (_: MouseEvent<unknown>, property: unknown) => {
    if (!setSortBy || !setSortOrder) return;

    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');

    if (typeof property !== 'string') return;

    setSortBy(property);
  };

  if (isLoading)
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );

  if (!data || !data.length) return <NoData text={noDataText} />;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
      }}
    >
      <TableContainer ref={ref}>
        <MuiTable
          stickyHeader
          aria-label={TABLE.NAME}
          component='div'
          onClick={onTableClick}
        >
          <TableHeader
            tableHeadCells={tableHeadCells}
            sortOrder={sortOrder}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
            aria-label={TABLE.HEADER}
          />
          <TableBody component='div' aria-label={TABLE.BODY}>
            <ViewportList viewportRef={ref} items={data}>
              {(item, index) => (
                <TableRow
                  key={index}
                  row={item}
                  tableHeadCells={tableHeadCells}
                />
              )}
            </ViewportList>
          </TableBody>
        </MuiTable>
        {pagination && page && limit && totalRowsCount && (
          <TablePagination
            component='div'
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            count={totalRowsCount}
            rowsPerPage={limit}
            aria-label={TABLE.PAGINATION}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        )}
      </TableContainer>
      <Popover
        open={Boolean(rowData)}
        onClose={closeContextMenu}
        anchorReference='anchorPosition'
        anchorPosition={contextMenuPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        transitionDuration={0}
        keepMounted
      >
        <ContextMenu items={newMenuItems} />
      </Popover>
      {actionDialog && actionDialog.dialog && actionDialog.props && (
        <actionDialog.dialog {...actionDialog.props} />
      )}
    </Box>
  );
}
