import { TableRow as MuiTableRow } from '@mui/material';
import { FC } from 'react';
import { HeadCell } from '@/components/TableLayout/types';
import { TableCellStyled } from '@/components/TableLayout/Table';

type TableRowProps<T> = {
  row: T;
  tableHeadCells: HeadCell[];
};

export const TableRow: FC<TableRowProps<any>> = ({ row, tableHeadCells }) => {
  return (
    <MuiTableRow component='div' data-row={JSON.stringify(row)}>
      {tableHeadCells.map(({ label, value, sx }) => (
        <TableCellStyled key={label} sx={sx}>
          {value(row)}
        </TableCellStyled>
      ))}
    </MuiTableRow>
  );
};
