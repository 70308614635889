import { useState } from 'react';
import { useSnackbar } from 'notistack';
import PersonIcon from '@mui/icons-material/Person';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Table } from '@/components/TableLayout/Table';
import { ConfirmationDialog } from '@/components/ConfirmationDialog/ConfirmationDialog';
import { copyToClipboard } from '@/utils/copyToClipboard';
import {
  COMMON_STRING,
  CONFIRMATION_STRING,
  SUCCESS_STRING,
} from '@/constants/dictionary';
import { ContextMenuItem } from '@/components/TableLayout/types';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { useLiveDataClients } from '@/service/hooks/api/useLiveDataClients';
import { TABLE_HEAD_CELLS } from '@/components/Table/LiveDataClients/constants';
import { LiveDataClient } from '@/service/hooks/types/LiveDataClients';

export const LiveDataClients = () => {
  const [isDisableClientOpen, setIsDisableClientOpen] = useState(false);
  const [liveDataClient, setLiveDataClient] = useState<LiveDataClient | null>(
    null,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { liveDataClients, isLoading, putLiveDataClient, mutate } =
    useLiveDataClients();

  const disableClient = async () => {
    await putLiveDataClient({
      ...liveDataClient,
      isEnabled: !liveDataClient?.isEnabled,
    } as any);
    setIsDisableClientOpen(false);
    setLiveDataClient(null);
    enqueueSnackbar(
      SUCCESS_STRING.CLIENT_ACTION(
        liveDataClient?.name,
        liveDataClient?.isEnabled,
      ),
      {
        variant: 'success',
      },
    );
    mutate(liveDataClients);
  };

  const menuItems: ContextMenuItem[] = [
    {
      Icon: PersonIcon,
      label: liveDataClient?.isEnabled ? 'Disable Client' : 'Enable Client',
      onClick: () => setIsDisableClientOpen((isOpen) => !isOpen),
      ref: setAnchorEl,
    },
    {
      Icon: ContentCopyIcon,
      label: COMMON_STRING.COPY_ID_LABEL,
      onClick: () => {
        if (!liveDataClient) return;

        enqueueSnackbar(SUCCESS_STRING.COPIED_TO_CLIPBOARD, {
          variant: 'success',
        });

        copyToClipboard(liveDataClient?.id);

        setLiveDataClient(null);
      },
    },
  ];

  return (
    <>
      <Table
        data={liveDataClients}
        tableName={TAB_NAMES.LIVE_DATA_CLIENTS}
        setRowData={setLiveDataClient}
        rowData={liveDataClient}
        isLoading={isLoading}
        tableHeadCells={TABLE_HEAD_CELLS}
        menuItems={menuItems}
      />
      <ConfirmationDialog
        isOpen={isDisableClientOpen}
        cancelHandler={() => {
          setIsDisableClientOpen(false);
          setLiveDataClient(null);
        }}
        anchorEl={anchorEl}
        confirmHandler={disableClient}
        title={liveDataClient?.name}
        body={CONFIRMATION_STRING.BODY.LIVE_DATA_CLIENT(
          liveDataClient?.isEnabled,
        )}
        confirmLabel={
          liveDataClient?.isEnabled
            ? COMMON_STRING.DISABLE
            : COMMON_STRING.ENABLE
        }
      />
    </>
  );
};
