import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { HeadCell, Order } from '@/components/TableLayout/types';
import { TableCellStyled } from '@/components/TableLayout/Table';

interface TableHeaderProps {
  tableHeadCells: HeadCell[];
  sortOrder?: Order;
  sortBy?: string;
  onRequestSort?: (event: MouseEvent<unknown>, property: unknown) => void;
}

export const TableHeader: FC<TableHeaderProps> = ({
  tableHeadCells,
  sortOrder,
  sortBy,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: unknown) => (event: MouseEvent<unknown>) => {
      if (!onRequestSort) return;

      onRequestSort(event, property);
    };

  return (
    <TableHead component='div'>
      <TableRow component='div'>
        {tableHeadCells.map(({ label, sortAttr, sx }) => (
          <TableCellStyled
            key={label}
            sx={{
              padding: (theme) => theme.spacing(0, 0, 0, 1),
              fontWeight: 'bold',
              ...sx,
            }}
          >
            <TableSortLabel
              active={!!sortBy && sortBy === sortAttr}
              disabled={!sortAttr}
              direction={sortBy === sortAttr ? sortOrder : 'asc'}
              onClick={createSortHandler(sortAttr)}
              sx={{
                '&:hover': {
                  color: 'inherit',
                },
                '&.Mui-active .MuiTableSortLabel-icon': {
                  color: 'inherit',
                },
              }}
            >
              {label}
            </TableSortLabel>
          </TableCellStyled>
        ))}
      </TableRow>
    </TableHead>
  );
};
