import { useContext, useState } from 'react';
import { useFixtureImport } from '@/service/hooks/api/useFixtureImport';
import { Table } from '@/components/TableLayout/Table';
import {
  SORT_ATTRIBUTES,
  TABLE_HEAD_CELLS,
} from '@/components/Table/FixtureImport/constants';
import { usePagination } from '@/service/hooks/table/usePagination';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { FixtureImportType } from '@/service/hooks/types/FixtureImport';
import { UIStateContext } from '@/context/ui/context';

export const FixtureImport = () => {
  const [fixtureImport, setFixtureImport] = useState<FixtureImportType | null>(
    null,
  );
  const { pageLimit, setPageLimit } = useContext(UIStateContext);
  const pagination = usePagination({
    defaultSortBy: SORT_ATTRIBUTES.REQUEST_TIMESTAMP,
    limit: pageLimit,
    setLimit: setPageLimit,
  });
  const { fixtureImports, isLoading } = useFixtureImport({
    limit: pagination.limit,
    page: pagination.page,
    sortOrder: pagination.sortOrder,
    sortBy: pagination.sortBy,
  });

  return (
    <Table
      tableName={TAB_NAMES.FIXTURE_IMPORT}
      tableHeadCells={TABLE_HEAD_CELLS}
      data={fixtureImports?.data}
      rowData={fixtureImport}
      optionalRowData={fixtureImport?.errors}
      totalRowsCount={fixtureImports?.pagination.results}
      setRowData={setFixtureImport}
      isLoading={isLoading}
      menuItems={[]}
      pagination={pagination}
    />
  );
};
