import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImgIcon from '@/assets/IMG-logo.png';
import { COMMON_STRING } from '@/constants/dictionary';
import { UserMenu } from '@/components/Navbar/UserMenu';
import { AnalyticsTheme } from '@/context/theme/types';
import { getEnvColor } from '@/context/theme/theme';
import { version } from '../../../package.json';
import { EnvironmentDetails } from './EnvironmentDetails';

export const Navbar = () => {
  const theme = useTheme<AnalyticsTheme>();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar
      sx={{
        borderTop: `${theme.spacing(0.5)} solid ${getEnvColor(theme)}`,
      }}
      position='sticky'
    >
      <Toolbar>
        <Stack flexGrow={1} gap={1} direction='row' alignItems='center'>
          <Stack gap={0.5}>
            <img src={ImgIcon} alt='IMG Arena Logo' width={130} height={20} />
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography fontWeight={700} fontSize='0.6em'>
                {isXS ? COMMON_STRING.SHORT_APP_NAME : COMMON_STRING.APP_NAME}
              </Typography>
              <Typography variant='subtitle2'>{version}</Typography>
            </Stack>
          </Stack>
          <EnvironmentDetails />
        </Stack>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
