import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useToken } from '@/service/hooks/api/useToken';
import {
  Order,
  PaginatedData,
  PaginatedResult,
} from '@/components/TableLayout/types';
import { fetchData, Fetches } from '@/service/apiRequests/fetchService';
import { URLS } from '@/utils/env';
import { Simulation } from '@/service/hooks/types/Simulation';
import { API, API_METHODS } from '@/service/apiRequests/constants';

export const useSimulations = (
  queryParams: Omit<PaginatedData, 'results'> & {
    sortBy: string;
    sortOrder: Order;
    ft: string;
  },
  fixtureId: string,
  simulationAction: string,
) => {
  const token = useToken();
  const {
    data: simulations,
    mutate,
    error,
  } = useSWR<PaginatedResult<Simulation>>(
    { token, endpoint: API.Simulation().GET, queryParams },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FS_BASE_URL),
    {
      keepPreviousData: true,
    },
  );

  const { trigger: triggerSimulationAction } = useSWRMutation(
    {
      token,
      endpoint: API.Simulation(simulationAction).POST,
      method: API_METHODS.POST,
    },
    (fetchParams: Fetches) =>
      fetchData(
        { ...fetchParams, queryParams: { fixtureId } },
        URLS.FS_BASE_URL,
      ),
  );

  return {
    simulations,
    error,
    isLoading: !simulations && !error,
    triggerSimulationAction,
    mutate,
  };
};
