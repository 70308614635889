import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { PaletteMode } from '@mui/material';
import {
  customOverridesDark,
  customOverridesLight,
  LIGHT_PALETTE_MODE,
  overrides,
} from '@/context/theme/constants';
import { AnalyticsTheme } from '@/context/theme/types';
import { DISPLAY_ENV, Environment } from '@/constants/environment';

export const analyticsThemeLight = () =>
  createTheme(deepmerge(customOverridesLight, overrides)) as AnalyticsTheme;

export const analyticsThemeDark = () =>
  createTheme(deepmerge(customOverridesDark, overrides)) as AnalyticsTheme;

export const getAnalyticsTheme = (mode: PaletteMode) => {
  return mode === LIGHT_PALETTE_MODE
    ? analyticsThemeLight()
    : analyticsThemeDark();
};

export const getEnvColor = (theme: AnalyticsTheme) => {
  const environment = import.meta.env.MODE as Environment;
  if (!DISPLAY_ENV.includes(environment)) {
    return;
  }
  return theme.env[environment];
};
