import { FixtureCell } from '@/components/TableLayout/FixtureCell';
import { HeadCell } from '@/components/TableLayout/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { Simulation } from '@/service/hooks/types/Simulation';
import { formatDate } from '@/utils/formatDate';
import { ActionCell } from '@/components/TableLayout/ActionCell';
import { DATE_FORMAT } from '@/utils/constants';
import { URLS } from '@/utils/env';

export const SORT_ATTRIBUTES = {
  START_DATE: 'StartDate',
};

export const TABLE_CELLS: HeadCell[] = [
  {
    label: 'Simulation name',
    value: ({ fixtureName }: Simulation) => fixtureName,
    sx: {
      width: 300,
    },
  },
  {
    label: 'Source fixture id',
    value: ({ fixtureId }: Simulation) => (
      <FixtureCell fixtureId={fixtureId} url={URLS.MT_BASE_URL} />
    ),
  },
  {
    label: 'Simulation start',
    value: ({ startDate }: Simulation) =>
      startDate
        ? formatDate(startDate, DATE_FORMAT.DATE_TIME_ZONE_FORMAT)
        : COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.START_DATE,
  },
  {
    label: 'Sport',
    value: ({ sportName }: Simulation) => sportName || COMMON_STRING.N_A,
  },
  {
    label: 'State',
    value: ({ simulationState }: Simulation) => simulationState,
  },
  {
    label: 'Speed',
    value: ({ speedRatio }: Simulation) => speedRatio,
  },
  {
    label: 'Scheduled Time',
    value: ({ scheduledTime }: Simulation) =>
      scheduledTime ? formatDate(scheduledTime) : COMMON_STRING.N_A,
  },
  {
    label: 'Remove when finished',
    value: ({ removeWhenFinished }: Simulation) =>
      removeWhenFinished.toString(),
  },
  {
    label: 'Last update',
    value: ({ lastUpdate }: Simulation) =>
      formatDate(lastUpdate, DATE_FORMAT.DATE_TIME_ZONE_FORMAT),
  },
  {
    label: '',
    value: () => <ActionCell />,
  },
];
