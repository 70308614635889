import { createContext, Dispatch, SetStateAction } from 'react';
import { TABLE_PAGINATION_OPTIONS } from '@/components/TableLayout/constants';
import { TableFiltersState } from '@/components/Filters/types';
import { TAB_NAMES } from '@/components/TabsBar/constants';

export enum PANEL {
  FILTERS = 'Filters',
}

export type Panel = PANEL | null;

export const initialTableFilters: TableFiltersState = {
  [TAB_NAMES.SIMULATIONS]: {
    filters: [],
    filtersHistory: [],
    ft: '',
  },
  [TAB_NAMES.AVERAGE_LATENCIES]: {
    filters: [],
    filtersHistory: [],
    ft: '',
  },
};

export interface UIState {
  fixtureId: string;
  pageLimit: number;
  openedFiltersDrawer: Panel;
  tableFilters: TableFiltersState;

  setOpenedFiltersDrawer: (panel: Panel) => void;
  setPageLimit: Dispatch<SetStateAction<number>>;
  setTableFilters: Dispatch<SetStateAction<TableFiltersState>>;
}

export const UIStateContext = createContext<UIState>({
  openedFiltersDrawer: null,
  fixtureId: '',
  pageLimit: TABLE_PAGINATION_OPTIONS[0],
  tableFilters: initialTableFilters,

  setOpenedFiltersDrawer: () => {},
  setPageLimit: () => {},
  setTableFilters: () => {},
});
